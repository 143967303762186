import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/home/Home';
import Aboutus from './pages/aboutus/Aboutus';
import Login from './pages/auth/login';
import Register from './pages/auth/register';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import Events from './pages/events/Events';
import EventDetails from './pages/events/EventDetails';
import News from './pages/news/News';
import Shop from './pages/shop/Shop';
import Sessions from './pages/sessions/Sessions';
import SessionDetails from './pages/sessionDetails/SessionDetails';




const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <React.StrictMode >
    <BrowserRouter >
    <div className="bg-bg1">



        <Routes>
                <Route exact path='/'  element={<Home />} />
                <Route exact path='/aboutus'  element={<Aboutus />} />
                <Route  path='/events'  element={<Events />} />

                <Route  path='/news'  element={<News />} />
                <Route  path='/shop'  element={<Shop />} />
                <Route  path='/sessions'   element={<Sessions />} />
                <Route  path='/sessions/:sessionId'   element={<SessionDetails />} />
         </Routes>


    </div>
    </BrowserRouter>

  </React.StrictMode>
);


