import React from 'react'
import NavBar from '../../Components/navBar/NavBar'
import Hero from '../../Components/hero'
import Footer from '../../Components/Footer/Footer'
import Gallery from './components/Gallery'
import Star from './components/Star'

function Card(props) {
    return (
        <div className='min-h-fit h-64 w-64   relative  border-[#042D71]   rounded-lg    border-b-0   lg:border-[15px] lg:border-b-[0px] border-8'>
            <img className='w-full h-60  object-cover z-0 relative  ' src={props.pic} alt="" />
            <div className=' z-20 relative -mt-4 lg:-ml-8 -ml-6 '>
                <div className='w-72 h-fit bg-Y font-pop font-semibold rounded-t-lg text-2xl text-center text-white  pt-3'>{props.name}</div>
                <div className='w-72 h-fit bg-Y font-pop font-light text-xl rounded-b-lg text-center text-white pb-3'>{props.dep}</div>
            </div>
        </div>



    )
}



export default function Aboutus() {
    return (
        <div className='w-full  overflow-hidden'>
            <NavBar />
            <Hero title='About igc' minititle='WE SHARE KNOWLEDGE SINCE 2018' />
            <div className='2xl:max-w-[1600px] 1xl:max-w-[1200px] xl:max-w-[1100px] w-[90%] mx-auto my-60 mt-20'>
                <div className=" my-8  mt-24 flex lg:flex-row md:flex-col sm:flex-col max-sm:flex-col justify-around items-center mx-auto">
                    <div className="2xl:w-[40%] xl:w-[40%] lg:w-[40%]">
                        <img className="" src="./assets/big-logo-banner.png" alt="" />
                    </div>
                    <div className="flex flex-col  lg:w-[50%] md:mt-8 w-[full%] sm:mt-8 max-sm:mt-8">
                        <h1 className="font-pop uppercase font-bold text-[#189EEA] text-2xl max-sm:text-xl flex justify-start items-center gap-4">About ISITCOM GOOGLE CLUB <svg className='hidden lg:block' width="350" height="4" viewBox="0 0 350 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 2H350" stroke="url(#paint0_linear_154_519)" strokeWidth="3" />
                            <defs>
                                <linearGradient id="paint0_linear_154_519" x1="-5.71415e-08" y1="2.5047" x2="349.986" y2="2.50456" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#4285F4" />
                                    <stop offset="0.9999" stopColor="#4285F4" stopOpacity="0" />
                                    <stop offset="1" stopColor="#FBBD12" stopOpacity="0" />
                                </linearGradient>
                            </defs>
                        </svg>
                        </h1>
                        <p className="lg:ml-4 font-pop text-base text-grey mt-2">
                        Established at our esteemed university, the ISITCOM Google Club is the heart of the IT student community. We offer a plethora of activities that range from hands-on workshops to enlightening seminars, all tailored to enrich the educational journey of our members. As a club, our focus is not just on learning, but also on creating memorable experiences and forging lifelong connections.
       </p>
                    </div>
                </div>
                <div className=" my-12  mt-24 m-auto">
                    <h1 className="font-pop uppercase font-bold text-[#189EEA] text-2xl max-sm:text-xl flex justify-start items-center gap-4" >hISTORY oF cREATION ISITCOM GOOGLE CLUB <svg className='hidden lg:block' width="954" height="4" viewBox="0 0 954 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.997559 2.49365H953.002" stroke="url(#paint0_linear_154_520)" strokeWidth="3" />
                        <defs>
                            <linearGradient id="paint0_linear_154_520" x1="0.997558" y1="2.99836" x2="952.964" y2="2.99728" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4285F4" />
                                <stop offset="0.9999" stopColor="#4285F4" stopOpacity="0" />
                                <stop offset="1" stopColor="#FBBD12" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                    </svg>
                    </h1>
                    <p className="lg:ml-4 font-pop text-base text-grey mt-2" >
                    Founded in 2018, our club was established with a clear vision: to bring together young students passionate about development and technology. Since its inception, the ISITCOM Google Club has hit many milestones, solidifying its place as a leading figure among university tech-focused clubs. Each year, we continue to grow, evolve, and inspire, all while staying true to our core mission of nurturing a passion for development among our members.
                    </p>

                </div>
            </div>


            <div className='w-full h-fit relative'>

                <div className="w-full   relative bg-Grad1 pb-96 pt-52  h-fit skewed-bottom  skewed-top-about ">

                    <img className='w-36 absolute top-12 right-16' src="./assets/G.png" alt="" />

                    <svg className="absolute top-96 right-14"


         width="60" height="56" viewBox="0 0 60 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint0_linear_154_1865)" />
            <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint1_linear_154_1865)" />
            <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint2_linear_154_1865)" />
            <defs>
                <linearGradient id="paint0_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9997FF" />
                    <stop offset="1" stopColor="#C0E8FF" />
                </linearGradient>
                <linearGradient id="paint1_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9997FF" />
                    <stop offset="1" stopColor="#C0E8FF" />
                </linearGradient>
                <linearGradient id="paint2_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9997FF" />
                    <stop offset="1" stopColor="#C0E8FF" />
                </linearGradient>
            </defs>
        </svg>



        <svg className="absolute bottom-0 right-96"


width="60" height="56" viewBox="0 0 60 56" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint0_linear_154_1865)" />
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint1_linear_154_1865)" />
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint2_linear_154_1865)" />
   <defs>
       <linearGradient id="paint0_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
       <linearGradient id="paint1_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
       <linearGradient id="paint2_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
   </defs>
</svg>
                    <Star t={96} b={"unset"} r={44} l={"unset"} />
                    <Star t={0} b={0} r={0} l={0} />
                    <Star t={0} b={0} r={0} l={0} />
                    <Star t={0} b={0} r={0} l={0} />


                    <svg className="absolute bottom-0 left-96"


width="60" height="56" viewBox="0 0 60 56" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint0_linear_154_1865)" />
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint1_linear_154_1865)" />
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint2_linear_154_1865)" />
   <defs>
       <linearGradient id="paint0_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
       <linearGradient id="paint1_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
       <linearGradient id="paint2_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
   </defs>
</svg>

<svg className="absolute bottom-96 left-32"


width="60" height="56" viewBox="0 0 60 56" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint0_linear_154_1865)" />
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint1_linear_154_1865)" />
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint2_linear_154_1865)" />
   <defs>
       <linearGradient id="paint0_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
       <linearGradient id="paint1_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
       <linearGradient id="paint2_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
   </defs>
</svg>
<svg className="absolute bottom-[60rem] left-32"


width="60" height="56" viewBox="0 0 60 56" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint0_linear_154_1865)" />
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint1_linear_154_1865)" />
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint2_linear_154_1865)" />
   <defs>
       <linearGradient id="paint0_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
       <linearGradient id="paint1_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
       <linearGradient id="paint2_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
   </defs>
</svg>
<svg className="absolute bottom-[80rem] right-72"


width="60" height="56" viewBox="0 0 60 56" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint0_linear_154_1865)" />
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint1_linear_154_1865)" />
   <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint2_linear_154_1865)" />
   <defs>
       <linearGradient id="paint0_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
       <linearGradient id="paint1_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
       <linearGradient id="paint2_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
           <stop stopColor="#9997FF" />
           <stop offset="1" stopColor="#C0E8FF" />
       </linearGradient>
   </defs>
</svg>

                    <div className=" mx-auto 2xl:max-w-[1600px] 1xl:max-w-[1200px] xl:max-w-[1100px] w-[90%] ">
                        <div className='mb-20 mt-8'>
                            <h1 className='font-monta text-5xl font-bold text-white'>IGC Executive Office</h1>
                            <h1 className='font-monta text-3xl font-light text-white mt-2'>2023 - 2024</h1>
                        </div>
                    </div>
                    <div className=" mx-auto 2xl:max-w-[1300px] 1xl:max-w-[1200px] xl:max-w-[1100px] w-[90%] mb-48 mt-12 ">

                        <div className=" flex w-full gap-20 justify-evenly flex-wrap">
                            <Card name='Yessine Trigui' dep='President' pic='./assets/office_2324/YT.webp' />
                            <Card name='Yasmine Kahlaoui' dep='Vice President' pic='./assets/office_2324/YK.webp' />

                        </div>
                        <div className=" flex w-full gap-20 justify-evenly flex-wrap my-32">

                            <Card name='Med Ali Baazaoui' dep='RH' pic='./assets/office_2324/DB.webp' />
                            <Card name='Malek Ben Khraief' dep='Organisation Manager' pic='./assets/office_2324/MK.webp' />
                            <Card name='Fatma Brahem' dep='Organisation Assistant' pic='./assets/office_2324/FB.webp' />

                        </div>
                        <div className=" flex w-full gap-20 justify-evenly flex-wrap">
                            <Card name='Ahmed Achour' dep='Technical Manager' pic='./assets/office_2324/AA.webp' />
                            <Card name='Eya Abid Ghozzi' dep='Technical Assistant' pic='./assets/office_2324/EA.webp' />
                            <Card name='Eya Kouki' dep='Technical Assistant' pic='./assets/office_2324/EK.webp' />
                        </div>

                        <div className=" flex w-full gap-20 justify-evenly flex-wrap my-32">
                        <Card name='Zeineb Chef' dep='Communication Manager' pic='./assets/office_2324/ZI.webp' />
                            <Card name='Farah Djebbi' dep='Communication Assistant' pic='./assets/office_2324/FD.webp' />
                            <Card name='Mohamed Ouragini' dep='External Relations Manager' pic='./assets/office_2324/MO.webp' />
                            <Card name='Safwen Yahaoui' dep='External Relations Assistant' pic='./assets/office_2324/SY.webp' />

                        </div>
                        <div className=" flex w-full gap-20 justify-evenly flex-wrap">
                            <Card name='Mariem Ben Abdallah' dep='Marketing Manager' pic='./assets/office_2324/MA.webp' />
                            <Card name='Amal Chkiwa' dep='Design Manager' pic='./assets/office_2324/AC.webp' />
                            <Card name='Faten Ouergli' dep='Media Manager' pic='./assets/office_2324/FO.webp' />
                            <Card name='Ines Naili' dep='Design Assistant' pic='./assets/office_2324/IN.webp' />
                        </div>

                    </div>
                </div>
                <div className=' skewed-bottom w-full h-24'></div>
            </div>






            <div className="flex gap-2 items-center justify-around   2xl:max-w-[1600px] xl:max-w-[1200px] w-[95%] mx-auto mt-24">

                <h1 className="font-monta font-bold text-Y w-fit  xl:text-3xl lg:text-2xl text-2xl uppercase   text-start">Our Gallery</h1>

                <div className="w-[60%] lg:inline-block hidden">

                    <svg viewBox="0 0 991 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 7.5L0 0.339746V17.6603L15 10.5V7.5ZM13.5 10.5H991V7.5H13.5V10.5Z" fill="url(#paint0_linear_57_403)" />
                        <defs>
                            <linearGradient id="paint0_linear_57_403" x1="-1.61792e-07" y1="9.5047" x2="990.96" y2="9.50354" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FBBD12" />
                                <stop offset="1" stopColor="#FBBD12" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>


            <Gallery />
            <Footer />
        </div>
    )
}
