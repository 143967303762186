import React from 'react'

export default function Hero(props) {
    return (
        <div className='h-fit py-20  border-t-8 border-[#001942] w-full bg-B flex justify-center items-center flex-col relative'>

            <svg className='absolute top-6 left-16' width="60" height="69" viewBox="0 0 60 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30 0L31.782 32.0497L60 34.0737L31.782 36.0977L30 68.1475L28.218 36.0977L0 34.0737L28.218 32.0497L30 0Z" fill="url(#paint0_linear_145_648)" />
                <defs>
                    <linearGradient id="paint0_linear_145_648" x1="6.15" y1="5.4518" x2="35.5745" y2="65.4118" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#ACAAFF" />
                        <stop offset="1" stopColor="#C0E8FF" />
                    </linearGradient>
                </defs>
            </svg>

            <svg className='absolute bottom-12 right-16' width="60" height="69" viewBox="0 0 60 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30 0L31.782 32.0497L60 34.0737L31.782 36.0977L30 68.1475L28.218 36.0977L0 34.0737L28.218 32.0497L30 0Z" fill="url(#paint0_linear_145_647)" />
                <defs>
                    <linearGradient id="paint0_linear_145_647" x1="6.15" y1="5.4518" x2="35.5745" y2="65.4118" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#ACAAFF" />
                        <stop offset="1" stopColor="#C0E8FF" />
                    </linearGradient>
                </defs>
            </svg>




            <div className='flex justify-center items-center gap-4'>
                <svg className='hidden lg:block 2xl:w-96 w-60' width="auto" height="12" viewBox="0 0 456 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M455.333 6C455.333 3.05448 452.946 0.666667 450 0.666667C447.054 0.666667 444.667 3.05448 444.667 6C444.667 8.94552 447.054 11.3333 450 11.3333C452.946 11.3333 455.333 8.94552 455.333 6ZM450 5L0 5V7L450 7V5Z" fill="url(#paint0_linear_145_658)" />
                    <defs>
                        <linearGradient id="paint0_linear_145_658" x1="450" y1="5.4953" x2="0.0179702" y2="5.49554" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                </svg>


                <h1 className='font-rale font-extrabold lg:text-5xl text-4xl my-6 mb-2 text-center text-white mx-12 uppercase'>{props.title}</h1>
                <svg className='hidden lg:block 2xl:w-96 w-60' width="auto" height="12" viewBox="0 0 456 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.666667 6C0.666667 8.94552 3.05448 11.3333 6 11.3333C8.94552 11.3333 11.3333 8.94552 11.3333 6C11.3333 3.05448 8.94552 0.666667 6 0.666667C3.05448 0.666667 0.666667 3.05448 0.666667 6ZM6 7H456V5H6V7Z" fill="url(#paint0_linear_145_657)" />
                    <defs>
                        <linearGradient id="paint0_linear_145_657" x1="6" y1="6.5047" x2="455.982" y2="6.50446" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                </svg>


            </div>
            <h1 className='font-rale font-black lg:text-2xl text-xl  text-center text-Y mx-12 uppercase'>{props.minititle}</h1>
            <svg width="50" height="50" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_145_653)">
                    <path d="M24 38L13 27L15 25L24 34L33 25L35 27L24 38ZM24 28L13 17L15 15L24 24L33 15L35 17L24 28Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_145_653">
                        <rect width="33.9411" height="33.9411" fill="white" transform="translate(0 24) rotate(-45)" />
                    </clipPath>
                </defs>
            </svg>
            <div className='w-full h-4 bg-white absolute bottom-2'></div>
            <div className='w-full h-4 bg-Y absolute bottom-0'></div>
        </div>
    )
}
