import React from 'react'
import { Link } from 'react-router-dom'

export default function Section1() {
  return (
    <div className='my-12 relative' >
        <div className='w-32 h-32 blur-xl bg-[#63FF93] rounded-full absolute top-4 left-28 z-10'></div>
        <div className='w-32 h-32 blur-xl bg-[#FFDF87] rounded-full bottom-10 right-28 absolute z-10' ></div>
        <div className='absolute top-5 lg:left-80 left-3'>
       <svg width="60" height="69" viewBox="0 0 60 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 0L31.782 32.0497L60 34.0737L31.782 36.0977L30 68.1475L28.218 36.0977L0 34.0737L28.218 32.0497L30 0Z" fill="url(#paint0_linear_34_258)"/>
            <defs>
            <linearGradient id="paint0_linear_34_258" x1="6.15" y1="5.4518" x2="35.5745" y2="65.4118" gradientUnits="userSpaceOnUse">
            <stop stopColor="#ACAAFF"/>
            <stop offset="1" stopColor="#C0E8FF"/>
            </linearGradient>
            </defs>
        </svg>
       </div>
       <div className='absolute bottom-5 lg:right-80 right-6'>
       <svg width="60" height="69" viewBox="0 0 60 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 0L31.782 32.0497L60 34.0737L31.782 36.0977L30 68.1475L28.218 36.0977L0 34.0737L28.218 32.0497L30 0Z" fill="url(#paint0_linear_34_258)"/>
            <defs>
            <linearGradient id="paint0_linear_34_258" x1="6.15" y1="5.4518" x2="35.5745" y2="65.4118" gradientUnits="userSpaceOnUse">
            <stop stopColor="#ACAAFF"/>
            <stop offset="1" stopColor="#C0E8FF"/>
            </linearGradient>
            </defs>
        </svg>
       </div>

        <div className='bg-blur border-2 border-white 2xl:max-w-[1600px] xl:max-w-[1100px] w-[90%] flex flex-col gap-2 justify-center items-center mx-auto py-20 z-20 relative'>

                <h1 className='font-rale font-semibold 2xl:text-5xl text-3xl text-center uppercase'>WANNA JOIN OUR ADVANTURES</h1>
                <h1 className='font-rale font-light 2xl:text-5xl  text-2xl text-center uppercase'>become A MEMBER NOW !</h1>
                <a target='_blank' rel="noreferrer"  href="https://forms.gle/ySCLBCfpaQ4MHAWq8"  className='px-4 bg-[#685E04] hover:rounded-lg  duration-700 h-fit rounded-2xl mt-4'> <div className='bg-Y  rounded-2xl h-full hover:px-12 duration-700 text-center flex justify-center items-center p-2 px-8    text-white font-monta text-2xl'>Member registration</div></a>
        </div>
    </div>

  )
}
