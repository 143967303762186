import React from 'react'

export default function Star(props) {
    return (
        <svg className={`absolute  ${(props.t!=="unset") ? "top-"+props.t : ""}
        ${(props.l!=="unset") ? "left-"+props.l : ""}
        ${(props.r!=="unset") ? "right-"+props.r : ""}
        ${(props.b!=="unset") ? "bottom-"+props.b : ""}

        `}

         width="60" height="56" viewBox="0 0 60 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint0_linear_154_1865)" />
            <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint1_linear_154_1865)" />
            <path d="M30 0.546875L31.782 26.2527L60 27.876L31.782 29.4994L30 55.2052L28.218 29.4994L0 27.876L28.218 26.2527L30 0.546875Z" fill="url(#paint2_linear_154_1865)" />
            <defs>
                <linearGradient id="paint0_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9997FF" />
                    <stop offset="1" stopColor="#C0E8FF" />
                </linearGradient>
                <linearGradient id="paint1_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9997FF" />
                    <stop offset="1" stopColor="#C0E8FF" />
                </linearGradient>
                <linearGradient id="paint2_linear_154_1865" x1="6.15" y1="4.91954" x2="26.4867" y2="56.588" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9997FF" />
                    <stop offset="1" stopColor="#C0E8FF" />
                </linearGradient>
            </defs>
        </svg>



    )
}
