import React from 'react'

export default function Gallery() {
  return (
    <div>
      <div className="lg:mt-12 mt-4 overflow-hidden">

<div className="lg:container mx-auto overflow-hidden" >
    <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-4  place-items-center">
        <div className="grid gap-4">
            <div>
                <img
                    className="h-auto max-w-full rounded-lg hover:-rotate-3 hover:scale-110 duration-700  border-4 border-B hover:border-primary hover:border-4 lazyload"
                    data-src="./assets/1.webp?v=123456"
                    alt="pic" width={400} height={500}
                />
            </div>
            <div>
                <img
                    className="h-auto max-w-full rounded-lg hover:-rotate-3 hover:scale-110 duration-700  border-4 border-Y hover:border-primary hover:border-4 lazyload"
                    data-src="./assets/2.webp?v=123456"
                    alt="pic" width={400} height={500}
                />
            </div>
            <div>
                <img
                    className="h-auto max-w-full rounded-lg hover:rotate-3 hover:scale-110 duration-700  border-4 border-B hover:border-primary hover:border-4 lazyload"
                    data-src="./assets/3.webp?v=123456"
                    alt="pic" width={400} height={500}
                />
            </div>
        </div>
        <div className="grid gap-4">
            <div>
                <img
                    className="h-auto max-w-full rounded-lg hover:-rotate-3 hover:scale-110 duration-700  border-4 border-R hover:border-primary hover:border-4 lazyload"
                    data-src="./assets/4.webp?v=123456"
                    alt="pic" width={400} height={500}
                />
            </div>
            <div>
                <img
                    className="h-auto max-w-full rounded-lg hover:-rotate-3 hover:scale-110 duration-700  border-4 border-Y hover:border-primary hover:border-4 lazyload"
                    data-src="./assets/5.webp?v=123456"
                    alt="pic" width={400} height={500}
                />
            </div>
            <div>
                <img
                    className="h-auto max-w-full rounded-lg hover:-rotate-3 hover:scale-110 duration-700  border-4 border-R hover:border-primary hover:border-4 lazyload"
                    data-src="./assets/6.webp?v=123456"
                    alt="pic" width={400} height={500}
                />
            </div>
        </div>
        <div className="grid gap-4">
            <div>
                <img
                    className="h-auto max-w-full rounded-lg hover:rotate-3 hover:scale-110 duration-700  border-4 border-B hover:border-primary hover:border-4 lazyload"
                    data-src="./assets/7.webp?v=123456"
                    alt="pic" width={400} height={500}
                />
            </div>
            <div>
                <img
                    className="h-auto max-w-full rounded-lg hover:-rotate-3 hover:scale-110 duration-700  border-4 border-Y hover:border-primary hover:border-4 lazyload"
                    data-src="./assets/8.webp?v=123456"
                    alt="pic" width={400} height={500}
                />
            </div>
            <div>
                <img
                    className="h-auto max-w-full rounded-lg hover:rotate-3 hover:scale-110 duration-700  border-4 border-G hover:border-primary hover:border-4 lazyload"
                    data-src="./assets/9.webp?v=123456"
                    alt="pic" width={400} height={500}
                />
            </div>
        </div>
        <div className="grid gap-4">
            <div>
                <img
                    className="h-auto max-w-full rounded-lg hover:rotate-3 hover:scale-110 duration-700  border-4 border-R hover:border-primary hover:border-4 lazyload"
                    data-src="./assets/10.webp?v=123456"
                    alt="pic" width={400} height={500}
                />
            </div>
            <div>
                <img
                    className="h-auto max-w-full rounded-lg hover:rotate-3 hover:scale-110 duration-700  border-4 border-Y hover:border-primary hover:border-4 lazyload"
                    data-src="./assets/12.webp?v=123456"
                    alt="pic" width={400} height={500}
                />
            </div>
            <div>
                <img
                    className="h-auto max-w-full rounded-lg hover:-rotate-3 hover:scale-110 duration-700  border-4 border-G hover:border-primary hover:border-4 lazyload"
                    data-src="./assets/12.webp?v=123456"
                    alt="pic" width={400} height={500}
                />
            </div>
        </div>
    </div>
</div>


</div>
    </div>
  )
}
