import React, { useEffect } from 'react'
import NavBar from '../../Components/navBar/NavBar'
import Hero from '../../Components/hero'
import Footer from '../../Components/Footer/Footer'


export default function Shop() {
    useEffect(() => {
        document.title = "IGC Shop";
      }, []);
  return (
    <div>
        <NavBar />
      <Hero title='Shop' miniTitle='' />
      <h1 className='font-monta font-semibold uppercase text-center mx-auto text-4xl my-12'>Opening Soon !</h1>
      <Footer/>
    </div>
  )
}
