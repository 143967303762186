import { TypeAnimation } from 'react-type-animation';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay,Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'lazysizes';
import { useEffect } from 'react';


function Hero() {
    useEffect(() => {
        // Create a new Image object to preload the image
        const image = new Image();
        const image2 = new Image();
        image.src = "./assets/bg1.png?v=123456";
        image2.src = "./assets/bg2.png?v=123456";

        // Once the image is loaded, it will be cached by the browser

    }, []);
    return (
        <div className="w-full bg-Y   2xl:h-[580px] lg:h-[400px]  h-[450px] py-40 lg:py-0 flex relative">
            <div className="lg:w-[50%] w-full flex justify-center items-center">
            <div className="flex w-full text-center 2xl:w-[75%] lg:w-[30vw] flex-col lg:gap-3 lg:pl-10 pl-5 ">

                <TypeAnimation className="font-sand font-bold lg:text-7xl text-5xl text-white uppercase"
                    sequence={[
                        // Same substring at the start will only be typed out once, initially
                        'IM BIG DEAL ',
                        1000, // wait 1s before replacing "Mice" with "Hamsters"
                        'IM BIG DEAL GOOGLE ME',
                        1000,
                        'IM BIG DEAL JOIN ME',
                        1000,

                    ]}
                    wrapper="span"
                    speed={10}
                    style={{ fontSize: '4.5rem', fontWeight: 'bold', display: 'inline-block' }}
                    repeat={Infinity}
                    preRenderFirstString={true}
                />


</div>

            </div>

            <div className="relative h-full  w-[65%]  hidden lg:block">
                <div className="absolute h-full z-10 -left-2">

                    <svg className="w-fit" width="314" height="auto" viewBox="0 0 314 580" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 0H24L289.335 583H0.5V0Z" fill="#FBBD12"/>
                        <path d="M24 0H55L314 583H289.305L24 0Z" fill="#F5F5F5"/>
                    </svg>

                </div>

                <Swiper className="h-full w-fill-av"
                    autoplay={true}
                    modules={[Autoplay,Pagination]}
                    pagination={true}

                >

                    <SwiperSlide className='bg-B relative' > <img data-src="./assets/bg1.webp?v=123456" className="h-auto max-w-full z-10 object-contain brightness-[.4] lazyload mix-blend-hard-light" alt="IGC " width={1300} height={500} />

                        <h1 className='absolute bottom-10 right-[400px] text-4xl font-bold font-rale uppercase text-white z-20'>"Google is fun"</h1>
                    </SwiperSlide>
                    <SwiperSlide className='bg-B relative' >
                    <h1 className='absolute bottom-10 right-[350px] text-4xl font-bold font-rale text-white uppercase z-20'>"Join now our Trainings"</h1>

                    <img data-src="./assets/bg2.webp?v=123456" className="h-auto max-w-full object-left-top brightness-[.4] lazyload mix-blend-hard-light" alt="IGC " width={1300} height={500} /></SwiperSlide>

                </Swiper>
            </div>
            <div className="absolute top-20 left-24  lg:block">
            <svg width="60" height="69" viewBox="0 0 60 69" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M30 0L31.782 32.0497L60 34.0737L31.782 36.0977L30 68.1475L28.218 36.0977L0 34.0737L28.218 32.0497L30 0Z" fill="white"/>
            </svg>


            </div>
            <div className="absolute bottom-6 lg:left-[35rem]">

            <svg width="60" height="69" viewBox="0 0 60 69" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M30 0L31.782 32.0497L60 34.0737L31.782 36.0977L30 68.1475L28.218 36.0977L0 34.0737L28.218 32.0497L30 0Z" fill="white"/>
            </svg>

            </div>

        </div>
    )
}


export default Hero;
