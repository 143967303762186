import React, { useEffect } from 'react'
import NavBar from '../../Components/navBar/NavBar'
import Hero from '../../Components/hero'
import { Link } from 'react-router-dom';

import Footer from '../../Components/Footer/Footer';
import { HiDotsHorizontal } from 'react-icons/hi';
import MainTitle from '../home/components/mainTitle';


function Card(props) {
    return (
      <div className='borderSpecial  p-4 2xl:w-[500px] w-[380px] rounded-xl'>
          <div className=' h-56 w-full'>
              <img className='w-full h-full object-cover rounded-lg brightness-75' src={props.pic} alt="" />
          </div>
          <div className='w-full h-fit p-2'>

              <div className='w-full pl-2 pt-1'>
                      <h1 className='font-monta font-bold uppercase text-xl opacity-50'>{props.cat}</h1>
                     <div className='flex justify-between items-center'>
                        <h1 className='font-monta uppercase text-2xl '>{props.title}</h1>
                        <Link to={props.linkTo}><HiDotsHorizontal className='text-lg'/></Link>
                     </div>
              </div>

          </div>
      </div>
    )
  }



export default function Sessions() {
    useEffect(() => {
        document.title = "IGC Sessions";
      }, []);
  return (
    <div>
        <NavBar />
      <Hero title='Sessions' miniTitle='' />
      <MainTitle title={"Our Upcoming Sessions"}/>
      <div className=' 2xl:max-w-[1600px] 1xl:max-w-[1250px] xl:max-w-[1100px] w-[95%] flex flex-wrap 2xl:gap-8 gap-4 justify-around mt-8 mx-auto'>
      <h1 className='text-center font-monta font-light uppercase text-7xl my-12'>Stay Tuned ...</h1>

    </div>

    <Footer />

    </div>
  )
}
