import React, { useEffect, useState } from 'react'
import NavBar from '../../Components/navBar/NavBar'
import Hero from '../../Components/hero'
import MainTitle from '../home/components/mainTitle'
import Footer from '../../Components/Footer/Footer'
import moment from 'moment';

function New(props){
    return(
        <div className='2xl:max-w-7xl xl:max-w-6xl w-[95%] mx-auto border-B border-2 relative my-28'>
            <div className='w-full h-56 relative'>
                <img className='w-full h-full object-cover brightness-50' src={props.pic} alt="" />
                <h1 className='px-16 py-4 bg-Y text-white font-pop text-3xl text-center h-fit w-fit absolute z-20 top-0 bottom-0 left-0 right-0 m-auto'>{props.subTitle}</h1>
            </div>
            <div className='w-full h-[65%] flex flex-col justify-center items-center pb-16 pt-8 px-24'>
                <h1 className='text-center font-rale font-bold text-5xl text-B uppercase pb-4'>{props.title}</h1>
                <p className='text-center font-ops font-2xl capitalize font-medium'>{props.desc}</p>
            </div>
            <span className='text-center font-ops font-2xl absolute bottom-2 left-2'> {moment(props.date).fromNow()}</span>
        </div>

    )
}


export default function News() {
    useEffect(() => {
        document.title = "IGC News";
      }, []);

      const [data, setData] = useState({})

      useEffect( () => {
           fetch("https://igc.tn/api/news/all", {
            method: "GET",
            headers: {

            },
          })
            .then((response) => response.json())
            .then((data) => {
              setData(data)
            })
            .catch((error) => console.log("errr:",error));
        }, []);


        if(data.length===null){
            return (
            <div>
            <NavBar />
          <Hero title='News' miniTitle='Check our LATEST NEWS AND UPDATES' />
          <MainTitle title={"Our Latest News"}/>
          <h1 className='text-center font-monta font-light uppercase text-7xl my-12'>Stay Tuned ...</h1>
            <Footer/>
        </div>
            )
        }else{
            return (
                <div>
                <NavBar />
              <Hero title='News' miniTitle='' />
              <MainTitle title={"Our Latest News"}/>
              {Array.isArray(data)
        ?  data.map((e)=>{
                        return(
                            <New subTitle={e.subTitle} title={e.title} desc={e.desc} pic={e.picUrl} date={e.created_at} />
                        )
                    }): null}
                <Footer/>
            </div>


            )

        }


}
