import React from 'react'
import { FaHandsHelping, FaChalkboardTeacher } from 'react-icons/fa'
import {TbUsersGroup} from 'react-icons/tb'
import {IoShareSocial} from 'react-icons/io5'
import { BsCalendarEvent } from 'react-icons/bs'
function Card(props){
    return (
        <div className='xl:w-[160px] xl:h-[160px] w-32 h-32 border-2 border-white rounded-lg flex justify-center items-center flex-col'>
        {props.icon}
        <div className='flex flex-col justify-center items-center mt-4'>
            <h1 className='xl:text-2xl text-xl text-white font-pop font-semibold '>{props.title}</h1>
            <h1 className='xl:text-2xl text-xl text-white font-pop font-semibold'>{props.desc}</h1>
        </div>
    </div>
    )
}

export default function Section6() {

    return (
        <div className='flex lg:flex-row flex-col w-full h-fit xl:h-[700px] box-content relative my-48'>
            <div className='lg:w-[55%] w-full flex justify-center items-center h-auto  bg-B py-32 box-content relative'>
                <div className='mx-auto lg:ml-16 lg:w-[70%] w-[90%]  h-full  '>
                <h1 className='font-monta  font-bold text-5xl uppercase text-white'>WHY igc ?</h1>
                <p className='font-monta text-lg capitalize lg:ml-6 text-white mt-4'>ISITCOM Google Club is more than just a name. It embodies a community, a hub for aspiring IT professionals at our university. We serve as the bridge that connects students with the latest trends in the world of technology. Through our club, we aim to foster an environment where students can collaborate, learn, and grow.
</p>
                </div>
            </div>
            <div className='lg:w-[45%] h-auto flex gap-4 gap-y-8 flex-wrap justify-around items-center mx-auto w-full py-2 px-24 bg-Y content-center md:py-8 sm:py-8  max-sm:py-8'>
                        <Card  title="250" desc="Member" icon=<TbUsersGroup className='xl:text-4xl  text-2xl  text-white'/> />
                        <Card  title="2" desc="Partner" icon=<FaHandsHelping className='xl:text-4xl  text-2xl  text-white'/> />
                        <Card  title="7800 " desc="Follower" icon=<IoShareSocial className='xl:text-4xl  text-2xl  text-white'/> />
                        <Card  title="100" desc="Training" icon=<FaChalkboardTeacher className='xl:text-4xl  text-2xl  text-white'/> />
                        <Card  title="5" desc="Event" icon=<BsCalendarEvent className='xl:text-4xl  text-2xl  text-white'/> />

            </div>
        </div>
    )
}
