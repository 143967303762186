import { Link } from "react-router-dom";
import {BiRightArrowAlt} from "react-icons/bi"

function LinkGen(props){
    if(props.viewAllLink===undefined){
            //
    }else{
        return(
            <Link to={props.viewAllLink} className="font-pop font-medium text-black w-fit  text-lg  text-end flex items-center opacity-50 justify-end">View All <BiRightArrowAlt /> </Link>
        )
    }
}


export default function MainTitle(props){


        return (
            <div className="flex gap-2 items-center justify-between  lg:mt-24 mt-8   2xl:max-w-[1600px] xl:max-w-[1200px] w-[95%] mx-auto">

                <h1 className="font-pop font-semibold text-Y lg:w-fit w-full  xl:text-3xl lg:text-2xl text-2xl uppercase   lg:text-start text-center">{props.title}</h1>

            <div className="2xl:w-[70%] w-[70%] lg:inline-block hidden">

            <svg  viewBox="0 0 991 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 7.5L0 0.339746V17.6603L15 10.5V7.5ZM13.5 10.5H991V7.5H13.5V10.5Z" fill="url(#paint0_linear_57_403)"/>
                <defs>
                <linearGradient id="paint0_linear_57_403" x1="-1.61792e-07" y1="9.5047" x2="990.96" y2="9.50354" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FBBD12"/>
                <stop offset="1" stopColor="#FBBD12" stopOpacity="0"/>
                </linearGradient>
                </defs>
                </svg>
            </div>
            {LinkGen(props)}

            </div>
        )


}
