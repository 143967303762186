import React from 'react'

export default function Section4() {
    return (
        <div className='w-full bg-B lg:border-t-[20px] border-t-8 border-Y   flex justify-between mt-48 lg:flex-row flex-col lg:h-[600px] h-fit '>
            <div className='lg:w-[55%] flex flex-col justify-center items-start lg:pl-12 gap-4 py-8 px-4 w-full'>
                <div className="flex  gap-2 items-center w-full ">
                    <h1 className="font-monta font-bold text-white  lg:text-4xl text-xl uppercase  w-fit ">Our Mission</h1>
                    <div className="w-[50%]">

                        <svg  viewBox="0 0 501 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.9952 7.67963L0.972982 0.586858L1.02702 17.9073L16.0045 10.6796L15.9952 7.67963ZM14.5045 10.6864L500.787 8.49989L500.778 5.4999L14.4952 7.68638L14.5045 10.6864Z" fill="url(#paint0_linear_39_143)" />
                            <defs>
                                <linearGradient id="paint0_linear_39_143" x1="1.00469" y1="9.75131" x2="500.792" y2="8.35622" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </linearGradient>
                            </defs>
                        </svg>

                    </div>
                </div>
                <p className='font-monta text-lg 2xl:ml-20 ml-6  text-white '>At ISITCOM Google Club, our mission is to empower IT students by providing them with the tools, knowledge, and experiences that will shape their future careers. Through our diverse range of trainings, events, and workshops, we strive to ensure that our members are always a step ahead, ready to tackle the challenges of the ever-evolving tech industry. Driven by passion and dedication, our club stands as a beacon of innovation and learning for every IT student at our university.
</p>

            </div>
            <div className='h-full lg:w-[40%] w-full border-b-8 lg:border-b-[20px] border-Y'>
                <img className='w-full h-full object-cover' src="./assets/pic1.png" alt="" />
            </div>
        </div>
    )
}
