import Hero from "./hero";

import MainTitle from "./components/mainTitle"
import { useEffect } from "react";

import Layout from '../layout'
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";
import Section6 from "./section6";
import Section7 from "./section7";
import Section8 from "./section8";
import Section9 from "./section9";


function Home() {
    useEffect(() => {
        document.title = "IGC";
      }, []);

  return (
    <Layout >
        <Hero />
        <Section1 />
        <Section2 />
        <MainTitle title={"Our Latest Sessions"} viewAllLink={"/sessions"}/>
        <Section3 />
        <Section4 />
        <MainTitle title={"Our Latest EVents"}/>
        <Section5 />
        <Section6 />
        <MainTitle title={"Check our latest news"}/>
        <Section7 />
        <Section8 />
        <Section9 />

    </Layout>
  );
}

export default Home;
