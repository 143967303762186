import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MainTitle from './components/mainTitle'
function Card(props){
    return (
        <div className='2xl:w-[600px] xl:w-[500px] w-[95%] border-2 border-Y h-fit'>
            <div className='h-32 w-full border-b-8 border-Y relative'>
                <img className='w-full h-full brightness-75 object-cover ' src={props.pic} alt="" />
                <h1 className='font-monta font-bold text-4xl uppercase text-white text-center absolute z-20 top-0 bottom-0 left-0 right-0 m-auto mt-10'>{props.title}</h1>
            </div>
            <div className='h-[70%] lg:px-8 px-2 py-2'>
                    <p className='font-ops text-lg p-4'>{props.desc}</p>
                <div className='flex justify-between items-center'>
                    <h1 className='font-ops text-lg'>{props.date}</h1>
                    <Link to="/news" className='capitalize font-monta text-lg underline underline-offset-1'>Read more</Link>

                </div>
            </div>

    </div>
    )
}

export default function Section7() {
    const [data, setData] = useState({})

    useEffect( () => {
         fetch("https://igc.tn/api/news/all", {
          method: "GET",
          headers: {

          },
        })
          .then((response) => response.json())
          .then((data) => {
            setData(data)
          })
          .catch((error) => console.log("errr:",error));
      }, []);


    if(data.length===0){
        return(
            <div className='flex lg:flex-row flex-col mx-auto gap-6 h-fit justify-between p-12 flex-wrap 2xl:max-w-[1600px] 1xl:max-w-[1200px] w-[90%] mb-48'>
                   <h1 className='text-center font-monta font-light uppercase text-7xl my-12'>Stay Tuned ...</h1>

 </div>
        )
    }else{
        return (
            <div className='flex lg:flex-row flex-col mx-auto gap-24 h-fit justify-center p-12 flex-wrap 2xl:max-w-[1600px] 1xl:max-w-[1200px] w-[90%] mb-48'>
                {Array.isArray(data)
        ? data.map((e)=>{
                    return(
                        <Card title={e.title} desc={e.desc} pic={e.picUrl} />
                    )
                }): null}

            </div>
        )
    }

}
