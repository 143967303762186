import React, { useEffect } from 'react'
import NavBar from '../../Components/navBar/NavBar'
import Hero from '../../Components/hero'
import { Link } from 'react-router-dom';

import Footer from '../../Components/Footer/Footer';
import { HiDotsHorizontal } from 'react-icons/hi';
import MainTitle from '../home/components/mainTitle';




export default function SessionDetails(props) {
    useEffect(() => {
        document.title = "IGC Sessions";
      }, []);
      useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
  return (
    <div>
        <NavBar />
      <Hero title='Web dev session' minititle='Exclusive ONLY FOR IGC members' />
      <div className='2xl:max-w-[1600px] 1xl:max-w-[1250px] xl:max-w-[1100px] w-[95%] mx-auto grid col-span-2'>
        <div>
            
        </div>
      </div>

    <Footer />

    </div>
  )
}
