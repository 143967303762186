import { Link } from "react-router-dom"

export default function NavItem(props) {

    if(props.link==="/#gallery"){
        return (
            <>
              <li  key={props.children} className="md:mr-2 w-fit  hover:scale-105 duration-700">
             <a href={props.link}  onClick={() => props.setIsOpen(false)} className={(props.state==="active")? "font-extrabold  w-fit text-lg p-2 text-B font-monta UnderLineHover duration-500 rounded-lg" : "font-medium w-fit text-lg p-2 text-black opacity-70 font-monta UnderLineHover duration-500 rounded-lg"}> {props.children}  </a>
             </li>
            </>
         )
    }else{
        return (
            <>
              <li  key={props.children} className="md:mr-2 w-fit hover:scale-105 duration-700">
             <Link   onClick={() => props.setIsOpen(false)} to={props.link} className={(props.state==="active")? "font-extrabold  w-fit text-lg p-2 text-B font-monta UnderLineHover duration-500 rounded-lg" : "font-medium w-fit text-lg p-2 text-black opacity-70 font-monta UnderLineHover duration-500 rounded-lg"}> {props.children} </Link>
             </li>
            </>
         )
    }

  }
