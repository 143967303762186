import React, {  useState } from "react";
import { Transition } from "@headlessui/react";
import NavItem from "./components/NavItem";
import {Link, useLocation} from 'react-router-dom';
import 'lazysizes';
function BB0(){
    return(
     <div>
              <Link to="/login"  className="lg:ml-6 md:ml-0 max-sm:ml-0 sm:ml-0 text-white py-2 px-6 lg:w-fit md:w-full max-sm:w-full sm:w-full font-monta md:rounded-none sm:rounded-t-none  md:rounded-b-lg  sm:rounded-b-lg max-sm:rounded-b-lg max-sm:rounded-none lg:rounded-2xl font-normal text-lg text-center bg-Y  hover:opacity-75 mr-4">Login</Link>

     </div>
    )
}
function BB(){
    return(
     <div>
           <Link to="/login" className="lg:ml-6 block md:ml-0 max-sm:ml-0 sm:ml-0 text-white py-2 px-6 lg:w-fit md:w-full max-sm:w-full sm:w-full font-monta md:rounded-none sm:rounded-t-none  md:rounded-b-lg  sm:rounded-b-lg max-sm:rounded-b-lg max-sm:rounded-none lg:rounded-full font-semibold text-lg text-center bg-B   hover:opacity-75">Login</Link>

     </div>
    )
}

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  return (
    <div>
        <div className="w-full bg-colors md:bg-B sm:bg-B max-sm:bg-B lg:py-3 flex justify-center items-center lg:p-0 p-3 text-center text-white font-monta font-normal lg:text-lg text-base">
        Breaking News: Google Me Feat. LilChef is OUT NOW !
        </div>
      <nav className="mx-auto w-full h-fit bg-bg1 py-4  z-20">
        <div className="flex flex-wrap items-center justify-between mx-auto">
          <div className="flex items-center w-full">
            <div className="flex items-center xl:mx-20 mx-2 justify-between w-full">
              <div className="flex justify-center items-center flex-shrink-0 ">
                <img  data-src="/assets/logo.png?v=123456" className="xl:h-14  h-10 w-auto lazyload lg:ml-0 ml-10" alt="IGC Logo" width={130} height={65} />
              </div>
              <div className="hidden lg:w-auto md:w-full sm:w-full max-sm:w-full lg:flex items-center">
                <ul
                  key=""
                  className="flex flex-col font-medium xl:p-4 lg:p-0 mt-4 border border-primary rounded-lg rounded-b-none md:flex-row md:space-x-6 lg:mt-0 lg:border-0  w-fit"
                >
                  <NavItem  setIsOpen={setIsOpen}  state={(location.pathname==="/")? "active": "none"} link="/">Home</NavItem>
                  <NavItem  setIsOpen={setIsOpen}  state={(location.pathname==="/aboutus")? "active": "none"} link="/aboutus">About Us</NavItem>
                  <NavItem  setIsOpen={setIsOpen}  state={(location.pathname==="/news")? "active": "none"} link="/news">News</NavItem>
                  <NavItem  setIsOpen={setIsOpen}  state={(location.pathname.startsWith("/events")) ? "active": "none"} link="/events">Events</NavItem>
                  <NavItem  setIsOpen={setIsOpen}  state={(location.pathname==="/shop")? "active": "none"} link="/shop">Shop</NavItem>
                  <NavItem  setIsOpen={setIsOpen}  state={(location.pathname==="/sessions")? "active": "none"} link="/sessions">Sessions</NavItem>
                </ul>
              <a target="_blank"  rel="noreferrer"  href="https://forms.gle/ySCLBCfpaQ4MHAWq8"  className="lg:ml-1 md:ml-0 max-sm:ml-0 sm:ml-0 text-[#574000] py-2 px-6 lg:w-fit md:w-full max-sm:w-full sm:w-full font-monta md:rounded-none sm:rounded-t-none  md:rounded-b-lg  sm:rounded-b-lg max-sm:rounded-b-lg max-sm:rounded-none lg:rounded-2xl font-normal text-lg text-center border-Y border-2  hover:opacity-75 mr-4">Join Us</a>

              </div>
            </div>
            <div className="mr-10 flex lg:hidden ">
              <button
                onClick={() => setIsOpen((isOpen) => !isOpen)}
                type="button"
                className="bg-B inline-flex items-center justify-center p-1 rounded-sm text-white  hover:bg-primary_shad focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-fifth0 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
                show={isOpen}
                enter="transition ease-in-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-out duration-100 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"

        >

            <div className={(isOpen) ?  "lg:hidden mx-2 pb-8" : "hidden"} id="mobile-menu">
              <ul
                key=""
                className="flex flex-col font-medium p-4 lg:p-0 mt-4 border-2 border-B rounded-sm border-b-0 md:flex-row md:space-x-8 lg:mt-0 lg:border-0 lg:bg-white w-full"
              >
                <NavItem  setIsOpen={setIsOpen}  state={(location.pathname==="/")? "active": "none"} link="/">Home</NavItem>
                <NavItem  setIsOpen={setIsOpen}  state={(location.pathname==="/aboutus")? "active": "none"} link="/aboutus">About Us</NavItem>
                  <NavItem  setIsOpen={setIsOpen}  state={(location.pathname==="/news")? "active": "none"} link="/news">News</NavItem>
                  <NavItem  setIsOpen={setIsOpen}  state={(location.pathname.startsWith("/events")) ? "active": "none"} link="/events">Events</NavItem>
                  <NavItem  setIsOpen={setIsOpen}  state={(location.pathname==="/shop")? "active": "none"} link="/shop">Shop</NavItem>
                  <NavItem  setIsOpen={setIsOpen}  state={(location.pathname==="/sessions")? "active": "none"} link="/sessions">Sessions</NavItem>
              </ul>
              <a  target="_blank"  rel="noreferrer" href="https://forms.gle/ySCLBCfpaQ4MHAWq8" className="lg:ml-6  block md:ml-0 max-sm:ml-0 sm:ml-0 text-B py-2 px-6 lg:w-fit md:w-full max-sm:w-full border-B border-2  sm:w-full font-monta  font-semibold text-lg text-center bg-[#F5F5F5] hover:opacity-75">Join Us</a>

            </div>

        </Transition>
      </nav>
    </div>
  );
}

export default NavBar;
