import { useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/navBar/NavBar";



export default function RootLayout({children}) {
    
  return (
    <div>
    <NavBar />
      {children}
      <Footer/>
    </div>
  )
}
