import React from 'react'
import {FaLocationDot} from "react-icons/fa6"
import {FaLongArrowAltRight} from "react-icons/fa"
import {BsCalendarDate} from "react-icons/bs"
import { Link } from 'react-router-dom'

function Card(props) {
    return (
      <div className='border-B border-2 rounded-sm p-4 2xl:w-[465px] w-[380px]'>
          <div className='rounded-lg border-b-8 border-b-Y h-56 w-full'>
              <img className='w-full h-full object-cover brightness-75' src={props.pic} alt="" />
          </div>
          <div className='w-full h-fit pb-4 pt-2'>
              <div className='flex gap-6 justify-start items-center pl-4 w-full'>
                  <h1 className='font-ops font-light text-lg flex justify-center items-center gap-1 capitalize' > <FaLocationDot className='text-xl '/> {props.location}</h1>
                  <h1 className='font-ops font-light text-lg flex justify-center items-center gap-1 capitalize'> <BsCalendarDate  className='text-xl mr-1' /> {props.date}</h1>
              </div>
              <div className='w-full pl-4 pt-1'>
                      <h1 className='font-rale font-semibold text-xl opacity-50'>{props.cat}</h1>
                      <h1 className='font-rale font-light text-2xl '>{props.title}</h1>
              </div>
              <div className='flex justify-start items-center w-full pt-5 gap-4' >
                  <Link to="" className='border-Y border-2 text-[#4A3806] font-monta font-medium font-lg py-2 px-8 rounded-lg hover:bg-Y hover:border-[#4A3806] hover:scale-105 duration-700'>Join Now</Link>
                  <Link to="" className='border-[#060D4A] border-2 text-[#060D4A] font-monta font-medium text-lg py-1.5 px-4 bg-B rounded-lg hover:text-white hover:px-8  hover:gap-8 duration-700 flex items-center gap-2'>More <FaLongArrowAltRight/> </Link>
              </div>
          </div>
      </div>
    )
  }

export default function Section3() {
  return (
    <div className=' 2xl:max-w-[1600px] 1xl:max-w-[1250px] xl:max-w-[1100px] w-[95%] flex flex-wrap 2xl:gap-8 gap-4 justify-around mt-8 mx-auto'>
                  <h1 className='text-center font-monta font-light uppercase text-7xl my-12'>Stay Tuned ...</h1>
    </div>

  )
}
