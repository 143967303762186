import React, { useEffect } from 'react'
import NavBar from '../../Components/navBar/NavBar'
import Footer from '../../Components/Footer/Footer'
import Hero from '../../Components/hero'
import MainTitle from '../home/components/mainTitle'
import { Link } from 'react-router-dom'
import {BsFillPinMapFill} from "react-icons/bs"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';



// import required modules
import { Scrollbar } from 'swiper/modules';

function Linkss(props){
    return(
        <div>
                    <Link className="font-bold flex items-center justify-center p-1 w-full mt-4 bg-B border-b-8 uppercase duration-700 hover:border-darkB border-B text-white  text-xl" to={props.link}>Check More</Link>

        </div>

    )
}
function Poster() {
    return (
        <div>
            <div className="my-16 relative">
                <div className='w-52 h-52 blur-lg bg-[#63FF93] rounded-full absolute top-4 left-28 z-10'></div>
                <div className='w-52 h-52 blur-lg bg-[#FFDF87] rounded-full bottom-2 right-28 absolute z-10' ></div>
                <div className="  2xl:max-w-[1600px] 1xl:max-w-[1200px] w-[90%] h-full bg-[#ffffff4b] border-2 border-white mx-auto py-8 relative z-20 rounded-2xl" >
                <div className="  lg:ml-[2rem]  w-[94%]
    flex justify-between md:px-10 max-sm:px-2 py-14 lg:flex-row flex-col mx-auto p-2 gap-4 bg-[#ffffff4b]  border-2 border-white   rounded-2xl" >
        <div className=" 3xl:w-[60%] 2xl:w-[70%] xl:w-[60%] lg:w-[80%]">
                            <h1 className="text-black text-5xl font-bold">Randonneé Bir Bechir</h1>
                            <h2 className="text-black ml-5 text-3xl font-light mb-4 mt-3">#El jaw</h2>
                            <p className="text-black ml-5 text-base">We're officially announcing our second edition of wintercamp after having its success last year. We're coming bigger this year! Stay tuned for more informations about the specific technology, planning, tickets and more.We're officially announcing our second edition of wintercamp after having its success last year. We're coming bigger this year! Stay tuned for more informations about the specific technology, planning, tickets and more.</p>
                            <div className="flex flex-col ml-5 ">
                                <div className="flex mt-4 lg:flex-row max-sm:flex-col max-sm:justify-start max-sm:items-start gap-2">
                                    <div className="flex mr-4 justify-center items-center">
                                        <span className="mr-2">
                                            <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.5 0C13.1944 0 17 3.86955 17 8.64289C17 12.2947 14.4765 16.2746 9.49402 20.6258C8.92229 21.1251 8.07769 21.1251 7.50369 20.6238L7.17436 20.3335C2.41236 16.0969 0 12.2133 0 8.64289C0 3.86955 3.80558 0 8.5 0ZM8.5 5.3187C6.69445 5.3187 5.23077 6.80699 5.23077 8.64289C5.23077 10.4788 6.69445 11.9671 8.5 11.9671C10.3055 11.9671 11.7692 10.4788 11.7692 8.64289C11.7692 6.80699 10.3055 5.3187 8.5 5.3187Z" fill="black" />
                                            </svg>
                                        </span>
                                        <span className="text-black">isitcom</span>
                                    </div>
                                    <div className="flex justify-center items-center xl:mt-0 lg:mt-5 ">
                                        <span className="mr-2">
                                            <svg width="24" height="25" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_75_580)">
                                                    <path d="M24.1877 4.5H21.7502V6H24.0002V22.5H3.00019V6H5.25019V4.5H2.81269C2.63737 4.50293 2.46434 4.54037 2.30349 4.61018C2.14264 4.67999 1.99712 4.78081 1.87524 4.90687C1.75336 5.03293 1.6575 5.18176 1.59315 5.34488C1.5288 5.50799 1.49721 5.68218 1.50019 5.8575V22.6425C1.49721 22.8178 1.5288 22.992 1.59315 23.1551C1.6575 23.3182 1.75336 23.4671 1.87524 23.5931C1.99712 23.7192 2.14264 23.82 2.30349 23.8898C2.46434 23.9596 2.63737 23.9971 2.81269 24H24.1877C24.363 23.9971 24.536 23.9596 24.6969 23.8898C24.8577 23.82 25.0033 23.7192 25.1251 23.5931C25.247 23.4671 25.3429 23.3182 25.4072 23.1551C25.4716 22.992 25.5032 22.8178 25.5002 22.6425V5.8575C25.5032 5.68218 25.4716 5.50799 25.4072 5.34488C25.3429 5.18176 25.247 5.03293 25.1251 4.90687C25.0033 4.78081 24.8577 4.67999 24.6969 4.61018C24.536 4.54037 24.363 4.50293 24.1877 4.5Z" fill="black" />
                                                    <path d="M6 10.5H7.5V12H6V10.5Z" fill="black" />
                                                    <path d="M10.5 10.5H12V12H10.5V10.5Z" fill="black" />
                                                    <path d="M15 10.5H16.5V12H15V10.5Z" fill="black" />
                                                    <path d="M19.5 10.5H21V12H19.5V10.5Z" fill="black" />
                                                    <path d="M6 14.25H7.5V15.75H6V14.25Z" fill="black" />
                                                    <path d="M10.5 14.25H12V15.75H10.5V14.25Z" fill="black" />
                                                    <path d="M15 14.25H16.5V15.75H15V14.25Z" fill="black" />
                                                    <path d="M19.5 14.25H21V15.75H19.5V14.25Z" fill="black" />
                                                    <path d="M6 18H7.5V19.5H6V18Z" fill="black" />
                                                    <path d="M10.5 18H12V19.5H10.5V18Z" fill="black" />
                                                    <path d="M15 18H16.5V19.5H15V18Z" fill="black" />
                                                    <path d="M19.5 18H21V19.5H19.5V18Z" fill="black" />
                                                    <path d="M7.5 7.5C7.69891 7.5 7.88968 7.42098 8.03033 7.28033C8.17098 7.13968 8.25 6.94891 8.25 6.75V2.25C8.25 2.05109 8.17098 1.86032 8.03033 1.71967C7.88968 1.57902 7.69891 1.5 7.5 1.5C7.30109 1.5 7.11032 1.57902 6.96967 1.71967C6.82902 1.86032 6.75 2.05109 6.75 2.25V6.75C6.75 6.94891 6.82902 7.13968 6.96967 7.28033C7.11032 7.42098 7.30109 7.5 7.5 7.5Z" fill="black" />
                                                    <path d="M19.5 7.5C19.6989 7.5 19.8897 7.42098 20.0303 7.28033C20.171 7.13968 20.25 6.94891 20.25 6.75V2.25C20.25 2.05109 20.171 1.86032 20.0303 1.71967C19.8897 1.57902 19.6989 1.5 19.5 1.5C19.3011 1.5 19.1103 1.57902 18.9697 1.71967C18.829 1.86032 18.75 2.05109 18.75 2.25V6.75C18.75 6.94891 18.829 7.13968 18.9697 7.28033C19.1103 7.42098 19.3011 7.5 19.5 7.5Z" fill="black" />
                                                    <path d="M9.75 4.5H17.25V6H9.75V4.5Z" fill="black" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_75_580">
                                                        <rect width="27" height="27" fill="black" />
                                                    </clipPath>
                                                </defs>
                                            </svg>

                                        </span>
                                        <span className="text-black">20 mars</span>
                                    </div>
                                </div>
                                <div className="flex lg:mt-5 lg:flex-row max-sm:flex-col max-sm:justify-start max-sm:items-start max-sm:mt-2 gap-2">
                                    <div className="flex mr-4 justify-center items-center ">
                                        <span className="mr-2">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.1805 0.825054C10.7382 0.294931 11.4733 0 12.2369 0H17.366C18.8207 0 20 1.1983 20 2.67647V7.84388C20 8.65513 19.6828 9.43315 19.1183 10.0068L10.2206 19.0481C8.97158 20.3173 6.94648 20.3173 5.69743 19.0481L0.936788 14.2106C-0.341789 12.9114 -0.307078 10.7943 1.01339 9.53913L10.1805 0.825054ZM15.1084 6.88236C16.1474 6.88236 16.9898 6.02644 16.9898 4.9706C16.9898 3.91476 16.1474 3.05883 15.1084 3.05883C14.0693 3.05883 13.227 3.91476 13.227 4.9706C13.227 6.02644 14.0693 6.88236 15.1084 6.88236Z" fill="black" />
                                            </svg>

                                        </span>
                                        <span className="text-black">80 tnd</span>
                                    </div>
                                    <div className="flex justify-center items-center  ">
                                        <span className="mr-2">
                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.5002 2.08331C6.75641 2.08331 2.0835 6.75623 2.0835 12.5C2.0835 18.2437 6.75641 22.9166 12.5002 22.9166C18.2439 22.9166 22.9168 18.2437 22.9168 12.5C22.9168 6.75623 18.2439 2.08331 12.5002 2.08331ZM15.9304 17.4031L11.4585 12.9312V6.24998H13.5418V12.0687L17.4033 15.9302L15.9304 17.4031Z" fill="black" />
                                            </svg>
                                        </span>
                                        <span className="text-black">8pm</span>
                                    </div>
                                </div>
                                <Link to=""  className='px-4 bg-[#685E04] hover:rounded-lg  duration-700 h-fit rounded-2xl mt-4 w-fit'> <div className='bg-Y  rounded-2xl h-full hover:px-12 duration-700 text-center flex justify-center items-center p-2 px-12    text-white font-monta text-xl'>I Want to join</div></Link>
                            </div>
                        </div>
                        <div className="lg:mt-[-1.5rem] xl:w-[30%] lg:w-[40%] 2xl:mr-[-10rem] xl:mr-[-6rem] lg:mr-[-6rem] md:mt-8 sm:mt-8 max-sm:mt-8 md:w-[80%] max-sm:mx-auto max-sm:w-[99%] md:h-96 sm:h-96 max-sm:h-96">
                            <img alt="" className="h-full w-full object-cover rounded-xl" src='./assets/pic1.png' />
                        </div>
                    </div>
                </div>

            </div>
            </div>
    )
}
function MiniPoster(props){
    return (
        <div className="w-[600px] h-fit min-h-fit  max-sm:col-span-2  border-[4px] pb-2  border-B " >
    <div className="h-fit p-2">
        <img className="w-full h-60 object-cover border-b-8 border-B "  src={props.picUrl} alt="" />
    </div>
    <div className="h-fit flex flex-col p-4 py-1   ">
        <h1 className="text-xl font-bold text-[#154087] font-rale">{props.title}</h1>
        <div className="flex justify-between items-center">
            <h2 className=" flex flex-row justify-center items-center ml-3 mt-1">
                <span className="mr-2" >
                    < BsFillPinMapFill className='text-xl'/>
                </span>
                <span className='text-xl font-light text-[#154087] font-rale' >{props.adress}</span>
            </h2>

        </div>
    </div>
</div>
    )
}
function Carousel() {
    return (
      <div className='2xl:max-w-[1400px] mx-auto h-[450px] my-24 '>
        <Swiper
          scrollbar={{
            hide: true,
          }}
          modules={[Scrollbar]}
          className="mySwiper h-full rounded-xl"
        >
          <SwiperSlide className='relative'> <img className='w-full h-full object-cover brightness-50' alt='' src='/assets/1.webp' />
          <h1 className='text-white absolute bottom-52 -left-40 font-monta font-bold text-4xl uppercase -rotate-90'>GOOGLE Open Day </h1>
          </SwiperSlide>
          <SwiperSlide> <img className='w-full h-full object-cover  brightness-50' alt='' src='/assets/10.webp' />
          <h1 className='text-white absolute bottom-52 -left-40 font-monta font-bold text-4xl uppercase -rotate-90'>GOOGLE Event 2020</h1>

          </SwiperSlide>
          <SwiperSlide> <img className='w-full h-full object-cover  brightness-50' alt='' src='/assets/8.webp' />

          </SwiperSlide>
          <SwiperSlide> <img className='w-full h-full object-cover  brightness-50' alt='' src='/assets/9.webp' />
          <h1 className='text-white absolute bottom-52 -left-40 font-monta font-bold text-4xl uppercase -rotate-90'>GOOGLE Office 2021</h1>

          </SwiperSlide>
          <SwiperSlide> <img className='w-full h-full object-cover  brightness-50' alt='' src='/assets/3.webp' />
          <h1 className='text-white absolute bottom-52 -left-40 font-monta font-bold text-4xl uppercase -rotate-90'>GOOGLE Open Day</h1>

          </SwiperSlide>

        </Swiper>
      </div>
    );
  }


export default function Events() {
    useEffect(() => {
        document.title = "IGC Events";
      }, []);
  return (
    <div className='bg-bg1'>
        <NavBar />
      <Hero title='Events' miniTitle='Check AND JOIN our AMAZING EVENTS' />
      <MainTitle title={"Upcoming events"}/>
            <h1 className='text-center font-monta font-light uppercase text-7xl my-12'>Stay Tuned ...</h1>
      <MainTitle title={"Memories <3"}/>
        <Carousel/>
      <MainTitle title={"Latest events"}/>
        <div className='my-12 gap-6  2xl:max-w-[1600px] 1xl:max-w-[1200px] xl:max-w-[1100px] w-[90%] flex flex-wrap justify-evenly items-center mx-auto'>
            <MiniPoster title="Dev Talk" link="5" picUrl="/assets/pic2.png" adress="ISITCOM, Sousse"/>
            <MiniPoster title="Google Open Day" link="4" picUrl="/assets/4.webp" adress="Kantawi, Sousse"/>
            <MiniPoster title="Randonne Barrage El Masri" link="3" picUrl="/assets/10.webp" adress="Barrage El Masri, Nabeul"/>
            <MiniPoster title="Lil Tech V2" link="2" picUrl="/assets/7.webp" adress="Hotel Rivera, Sousse"/>
            <MiniPoster title="Randonne Ain Mazen" link="1" picUrl="/assets/12.webp" adress="Ain Mazen"/>
            <MiniPoster title="Lil Tech V1" link="0" picUrl="/assets/9.webp" adress="ISITCOM, Sousse"/>
        </div>
        <Footer />

    </div>
  )
}
