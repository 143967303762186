import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi";
import {AiFillFacebook,AiFillInstagram,AiFillYoutube} from "react-icons/ai"




function Footer() {
    return (
        <div className="mt-48">

            <div className="bg-B pt-52 pb-24 text-white w-full h-fit  skewed-top ">


                <div className="lg:w-[90%] w-[96%] lg:px-12 px-4 mx-auto flex  justify-between lg:flex-row flex-col gap-5">
                    <div className="flex items-start flex-col w-fit">
                        <div>
                            <h1 className="text-8xl font-monta font-bold uppercase">IGC</h1>
                            <h2 className="text-lg font-monta font-light uppercase">Let's Shine Together</h2>
                        </div>
                        <div className="my-4 flex justify-start flex-col items-start">
                            <h3 className="flex items-center justify-center gap-2 font-pop text-lg"><HiOutlineMail /> <span>isitcomgclub@gmail.com</span></h3>
                            <h3 className="flex items-center justify-center gap-2 font-pop text-lg"><HiOutlinePhone /> (00216) 93 114 562</h3>
                            <h3 className="flex items-center justify-center gap-2 font-pop text-lg"> <HiOutlinePhone /> (00216) 51 768 808</h3>
                        </div>
                    </div>
                    <div>
                    <div className="flex gap-20 md:flex-row flex-col ">
                        <div className="flex justify-start flex-col">
                            <h1 className="text-3xl font-bold font-monta">General</h1>
                            <a href="https://igc.tn" className="text-lg font-normal font-monta">Home</a>
                            <a href="https://igc.tn/news" className="text-lg font-normal font-monta">News</a>
                            <a href="https://igc.tn/events" className="text-lg font-normal font-monta">Events</a>
                            <a href="https://igc.tn/shop" className="text-lg font-normal font-monta">Shop</a>
                            <a href="https://igc.tn/sessions" className="text-lg font-normal font-monta">Sessions</a>
                        </div>
                        <div className="flex justify-start flex-col">
                            <h1 className="text-3xl font-bold font-monta">Support</h1>
                            <a href="https://igc.tn" className="text-lg font-normal font-monta">Privacy Policy</a>
                            <a href="https://igc.tn" className="text-lg font-normal font-monta">Terms & Conditions</a>
                            <a href="https://igc.tn" className="text-lg font-normal font-monta">Cookies use</a>
                        </div>
                        <div className="flex justify-start flex-col">
                            <h1 className="text-3xl font-bold font-monta">Follow Us</h1>
                            <div className="flex gap-2 items-center">
                            <a href="https://www.facebook.com/GoogleClubIsitcom" className="text-5xl font-normal font-monta text-primary">  <AiFillFacebook/> </a>
                            <a href="https://www.instagram.com/isitcomgoogleclub/?hl=en" className="text-5xl font-normal font-monta text-primary"> <AiFillInstagram/> </a>
                            <a href="https://igc.tn" className="text-5xl font-normal font-monta text-primary"> <AiFillYoutube/> </a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

            </div>

<div className="bg-black flex justify-center items-center py-6">
    <h1 className="text-white font-pop font-light text-center">Copyright @2023 EDI Development | Developed&Designed By <a className="text-[#4e67d7] font-normal" href="https://dev.edigroup.tn/">EDI-Development</a></h1>
</div>

        </div>
    );
}

export default Footer;
