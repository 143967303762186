import React from 'react'
function Card(props) {
    return (
        <div className='min-h-fit w-64  border-[#042D71] lg:border-[20px] border-8 relative 2xl:first:mt-32 2xl:last:mt-32'>
            <img className='w-full h-60 brightness-75 object-cover z-0 relative' src={props.pic} alt="" />
            <div className='-mb-8 z-20 relative lg:-ml-8 -ml-6'>
                <div className='w-72 h-10 bg-Y font-pop font-semibold text-2xl text-center text-white p-1'>{props.name}</div>
                <div className='w-72 h-8 bg-[#A37D14] font-pop font-light text-xl rounded-b-xl text-center text-white'>{props.dep}</div>
            </div>
        </div>



    )
}

export default function Section8() {

    return (
        <div className='w-full bg-B border-t-8 border-b-8 border-Y h-fit py-24 lg:pt-24 pt-4 flex justify-center items-center flex-col'>
            <div className='flex justify-center items-center gap-4'>
                <svg className='hidden lg:block 2xl:w-96 w-60' width="auto" height="24" viewBox="0 0 450 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M430 14L450 23.547V0.452994L430 10V14ZM432 10L0 10V14L432 14V10Z" fill="url(#paint0_linear_64_312)" />
                    <defs>
                        <linearGradient id="paint0_linear_64_312" x1="450" y1="11.4953" x2="0.0179702" y2="11.4955" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                </svg>

                <h1 className='font-pop font-semibold lg:text-4xl text-3xl my-6 text-center text-white'>MEMBERS OF THE MONTH</h1>
                <svg className='hidden lg:block 2xl:w-96 w-60' width="auto" height="24" viewBox="0 0 450 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 10L0 0.452994V23.547L20 14V10ZM18 14H450V10H18V14Z" fill="url(#paint0_linear_64_310)" />
                    <defs>
                        <linearGradient id="paint0_linear_64_310" x1="-7.34677e-08" y1="12.5047" x2="449.982" y2="12.5045" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>

            <div className='mx-auto flex lg:flex-row flex-col justify-center items-center 2xl:max-w-[1600px] 1xl:max-w-[700px]   lg:max-w-[700px] w-[90%] 3xl:gap-32 gap-16 h-full flex-wrap'>
                <Card name='???????' dep='Dep. technique' pic='./assets/who.png' />
                <Card name='???????' dep='Dep. Communication' pic='./assets/who.png' />
                <Card name='???????' dep='Dep. Design' pic='./assets/who.png' />
                <Card name='???????' dep='Dep. Media' pic='./assets/who.png' />
            </div>
            <h1 className='font-monta font-black lg:text-5xl  text-2xl text-center text-white uppercase lg:mt-8 mt-24 '  >WE ARE PROUD OF THEIR <br /> WORK AND EFFORTS DURING THIS MONTH</h1>
            <svg className='mt-8 w-[70%]' viewBox="0 0 1108 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.679491 18L18 35.3205L35.3205 18L18 0.679491L0.679491 18ZM1107.37 18L1090.05 0.679491L1072.73 18L1090.05 35.3205L1107.37 18ZM18 21H1090.05V15H18V21Z" fill="#042D71" />
            </svg>
            <h1 className='font-monta font-medium lg:text-5xl  text-2xl text-center text-white uppercase lg:mt-8 mt-12'  >keep shine chabeb</h1>

        </div>
    )
}
