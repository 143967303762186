import React from 'react'
function Card(props){
    return (
            <div className='lg:h-64 lg:w-64 w-40 h-40  border-[#042D71] lg:border-[12px] border-8 relative '>
                <img className='w-full h-full grayscale hover:grayscale-0 object-cover z-0 relative' src={props.pic} alt="" />
            </div>



    )
}

export default function Section9() {

    return (
        <div className='w-full my-48'>
            <div className='flex justify-center items-center gap-4'>

           <svg className='hidden lg:block 2xl:w-96 w-60' width="auto" viewBox="0 0 450 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M430 14L450 23.547V0.452994L430 10V14ZM432 10L0 10V14L432 14V10Z" fill="url(#paint0_linear_71_148)"/>
<defs>
<linearGradient id="paint0_linear_71_148" x1="450" y1="11.4953" x2="0.0179702" y2="11.4955" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="0.9999" stopOpacity="0.01"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
</defs>
</svg>

            <h1 className='font-pop font-semibold lg:text-4xl text-3xl my-3 text-center uppercase'>OuR OFFICIEL PARTNERS</h1>

            <svg  className='hidden lg:block 2xl:w-96 w-60' width="auto"  height="24" viewBox="0 0 450 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 10L0 0.452994V23.547L20 14V10ZM18 14H450V10H18V14Z" fill="url(#paint0_linear_71_147)"/>
<defs>
<linearGradient id="paint0_linear_71_147" x1="-7.34677e-08" y1="12.5047" x2="449.982" y2="12.5045" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
<stop offset="1" stopOpacity="0"/>
</linearGradient>
</defs>
</svg>


            </div>

                <div className='mx-auto flex lg:flex-row flex-col justify-center items-center 2xl:max-w-[1600px] 1xl:max-w-[700px] h-fit   lg:max-w-[700px] w-[90%] 3xl:gap-32 gap-16 flex-wrap p-12'>
                        <Card pic='./assets/cronos.png' />
                </div>

        </div>
    )
}
